import React, { useState } from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql, Link } from "gatsby";
import logo from "../assets/api-logo.svg";
import closeIcon from "../assets/close-bold.svg";
import navIcon from "../assets/navigation-menu.svg";

const BrandHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  border-bottom: solid 4px rgb(10 105 241);
  transition: transform 0.5s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  background: white;

  .brand-header--logo-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .brand-header--list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .brand-header--list-item-link {
    text-decoration: none;
    color: #666666;
    font-size: 15px;
    font-weight: 300;
    line-height: normal;
    display: block;
    padding: 6px 0;
  }

  .brand-header--nav {
    display: flex;
    flex-direction: column;
    margin: 0;
    transition: all 0.5s ease;
    max-height: 0;
    overflow: hidden;
  }
  .brand-header--nav.expanded {
    max-height: 300px;
    transform: translate(0, 0%);
    padding-bottom: 24px;
  }

  .menu-icon-wrapper {
    height: 24px;
    width: 24px;
  }
  .brand-header--logo-link {
    font-size: 0;
  }
  .menu-icon-wrapper img.expanded {
    display: none;
  }
  .menu-icon-wrapper.expanded img.closed {
    display: none;
  }
  .menu-icon-wrapper.expanded img.expanded {
    display: unset;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 24px;

    .brand-header--logo-menu {
      margin: 0;
    }
    .menu-icon-wrapper {
      display: none;
    }
    .brand-header--logo-link {
      margin-right: 16px;
    }
    .brand-header--nav {
      max-height: unset;
    }
    .brand-header--nav {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }
    .brand-header--list {
      display: flex;
    }
    .brand-header--list-item-link {
      padding: 0 6px;
    }
    .brand-header--nav.expanded {
      max-height: unset;
      transform: translate(0, 0%);
      padding-bottom: 0;
    }
  }
`;

export default function BrandHeaderWrapper() {
  const [expanded, setExpanded] = useState(false);

  const {
    site: {
      siteMetadata: { basePath }
    }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          basePath
        }
      }
    }
  `);

  return (
    <BrandHeader className="brand-header">
      <div className="brand-header--logo-menu">
        <Link className="brand-header--logo-link" to={basePath}>
          <img src={logo} alt="API.bible Logo" height="36" />
        </Link>
        <div
          className={`menu-icon-wrapper ${expanded ? "expanded" : ""}`}
          onClick={() => setExpanded(!expanded)}
        >
          <img src={navIcon} className="closed" alt="Menu" />
          <img src={closeIcon} className="expanded" alt="Close Menu" />
        </div>
      </div>
      <nav className={`brand-header--nav ${expanded ? "expanded" : ""}`}>
        <ul className="brand-header--list">
          <li>
            <a
              href="https://scripture.api.bible/"
              className="brand-header--list-item-link"
            >
              Home
            </a>
          </li>
          <li>
            <a href="/" className="brand-header--list-item-link">
              Documentation
            </a>
          </li>
          <li>
            <a
              href="https://scripture.api.bible/livedocs"
              className="brand-header--list-item-link"
            >
              API Reference
            </a>
          </li>
        </ul>
        <ul className="brand-header--list">
          <li>
            <a
              href="https://scripture.api.bible/admin/applications"
              className="brand-header--list-item-link"
            >
              Apps
            </a>
          </li>
          <li>
            <a
              href="https://scripture.api.bible/admin/account"
              className="brand-header--list-item-link"
            >
              Settings
            </a>
          </li>
        </ul>
      </nav>
    </BrandHeader>
  );
}
