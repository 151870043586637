import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useSidebar } from "@rocketseat/gatsby-theme-docs-core";

import { Container, List, Heading, Item, SubItem } from "./styles";
import { isExternalUrl } from "../../util/url";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";

function ListWithSubItems({ children, text }) {
  return (
    <>
      <Heading>{text}</Heading>
      <SubItem>{children}</SubItem>
    </>
  );
}

export default function Sidebar({ isMenuOpen }) {
  const {
    site: {
      siteMetadata: { footer },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          footer
        }
      }
    }
  `);

  const data = useSidebar();

  function renderLink(link, label) {
    return isExternalUrl(link) ? (
      <ExternalLink link={link} label={label} />
    ) : (
      <InternalLink link={link} label={label} />
    );
  }

  return (
    <Container isMenuOpen={isMenuOpen}>
      <nav>
        <List className="manual-sidebar-list show-on-mobile">
          <Heading>Navigation</Heading>
          <Item>
            <a href="https://scripture.api.bible/" target="_self">
              Home
            </a>
          </Item>
          <Item>
            <a href="/" title="Documentation" class="active">
              Documentation
            </a>
          </Item>
          <Item>
            <a
              href="https://scripture.api.bible/livedocs"
              title="API Reference"
            >
              API Reference
            </a>
          </Item>
          <Item>
            <a
              href="https://scripture.api.bible/admin/applications"
              target="_self"
            >
              Apps
            </a>
          </Item>
          <Item>
            <a href="https://scripture.api.bible/admin/account" target="_self">
              Settings
            </a>
          </Item>
        </List>
        <List>
          {data.map(({ node: { label, link, items, id } }) => {
            if (Array.isArray(items)) {
              const subitems = items.map((item) => {
                return (
                  <Item key={item.link}>
                    {renderLink(item.link, item.label)}
                  </Item>
                );
              });

              return (
                <ListWithSubItems key={id} text={label}>
                  {subitems}
                </ListWithSubItems>
              );
            }

            return <Item key={id}>{renderLink(link, label)}</Item>;
          })}
        </List>
      </nav>
      <footer>
        <p>{footer}</p>
      </footer>
    </Container>
  );
}

ListWithSubItems.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]).isRequired,
  text: PropTypes.string.isRequired,
};

Sidebar.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};
